import React, { createContext, ReactNode, useContext, useState } from "react";
import { StorageContext } from "./StorageContext";
import { useLocation } from "react-router-dom";

const DropContext = createContext<{
  // StorageMain에서 drag and drop 기능에 필요한 file drag여부 확인 
  inFileDragging?: any, 
  setInFileDragging?: any;
}>({});

function DropContextProvider({children}: { children: ReactNode }) {
  // stroageMain 외의 페이지 내 drag and drop 방지
  const [inFileDragging, setInFileDragging] = useState(false);
  const [fileDragging, setFileDragging] = useState(false);
  // 전역으로 관리
  const { 
    handleDrop,
    dragTag,
    setDragTag,
  } = useContext(StorageContext);
  const { pathname } = useLocation();

  // console.log("전역으로 받은 상태값", dragTag)
  return (
    <div 
      className={`file-droppable ${fileDragging ? 'drag-prepared': ''}`}
      onDragStart={(e)=>{
        setInFileDragging(true);
      }}
      onDragEnter={(e)=>{
        e.stopPropagation();
        e.preventDefault();
        //storageMain에 사이드 패널 tag넣기
        if (dragTag.valid) return;
        if (inFileDragging) return;
        setFileDragging(true);
      }}
      onDragOver={(e)=>{
        e.stopPropagation();
        e.preventDefault();
      }}
      onDragEnd={() => {
        setFileDragging(false);
        setInFileDragging(false);
      }}
      onDrop={(e)=>{
        e.stopPropagation();
        e.preventDefault();
        if (inFileDragging) return;
        const dt = e.dataTransfer;
        if (!dt.files[0]) return;
        handleDrop(dt.files, pathname.includes('smc'));
        setFileDragging(false);
      }}
      onMouseLeave={() => {
        setFileDragging(false);
      }}
    >
      <DropContext.Provider value={{
        inFileDragging, setInFileDragging,
      }}>
        {children}
      </DropContext.Provider>
    </div>
  )
}

export default DropContextProvider
