
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function Recruit() {
  const navigate = useNavigate();

  useEffect(() => {
    const targetURL = "https://vience-recruit.notion.site";
    window.location.href = targetURL;
  }, []);
  

  return (
    <div>
      <p>잠시만 기다려 주세요... 리다이렉트 중입니다.</p>
    </div>
  )
}