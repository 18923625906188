import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LoginProvider } from './utils/Context';
import HttpsRedirect from 'react-https-redirect';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  //<React.StrictMode>
  <HttpsRedirect>
    <LoginProvider>
      <App />
    </LoginProvider>
  </HttpsRedirect>  
  //</React.StrictMode>
);

reportWebVitals();
