import React, { useContext, useEffect, useState } from "react";

import { CustomThemeContext } from "../../../utils/Context";
import { StorageContext } from "../../../context/StorageContext";
import { useLocation, useNavigate } from "react-router-dom";
import CanvasSearch from "../../../components/canvasSearch/CanvasSearch";
import { getDirectoryStatus } from "../apis/api";
import { useAPIwithCookies } from "../../../hooks/useApiCookies";

export default function StorageHeader({ baseDir, setKeyword, fileNdirList }) {
  // console.log(fileNdirList);

  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useContext(CustomThemeContext);
  const {
    curDir,
    setCurDir,
    renameCurDirectory,
    createDirectory,
    selectOpen,
    setSelectOpen,
  } = useContext(StorageContext);

  //dir select
  // const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const api = useAPIwithCookies();

  //@@@@@@@@@@@@@@@@@@ MSW @@@@@@@@@@@@@@@@@@//
  const [dirStatus, setDirStatus] = useState<any>([]);

  useEffect(() => {
    const getFetchData = async () => {
      try {
        const response = await api.get("/tag/tagging_progress/" + curDir);
        // console.log("현재 디렉토리 경로에 따른 tag", response.data);
        setDirStatus(response.data);
      } catch (error) {
        // console.error("Error fetching pipe list", error);
      }
    };

    getFetchData();
  }, [curDir]);
  //@@@@@@@@@@@@@@@@@@ MSW @@@@@@@@@@@@@@@@@@//
  return (
    <>
      {" "}
      {!location.pathname.includes("smc") && (
        <div className="process-root">
          <div className="process-root-header-left">
            <div
              onClick={() => {
                setCurDir("");
                navigate("/vience-canvas/storage");
              }}
              className="dir-name"
            >
              <span>My Drive</span>
              {curDir !== "" && (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5791 18.7734L15.5791 12.7734L9.5791 6.77344"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}

              {/* LINE */}
              {curDir === "" && (
                <>
                  <img
                    id="root-selector"
                    src={
                      process.env.PUBLIC_URL + theme === "dark"
                        ? "/images/data-root-down.svg"
                        : "/images/data-root-down-white.svg"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectOpen(!selectOpen);
                    }}
                    alt=""
                  />
                  {selectOpen && (
                    <ul id="root-select">
                      <li className="root-option" onClick={createDirectory}>
                        Create New Folder
                      </li>
                      <li className="root-option">Delete Current Folder</li>
                    </ul>
                  )}
                </>
              )}
              {/* LINE */}
            </div>
            {curDir !== baseDir &&
              curDir.split("/").map((dir, index) => {
                if (index !== curDir.split("/").length - 1) {
                  let targetPath = "";

                  for (let i = 0; i <= index; i++) {
                    targetPath += `${i === 0 ? "" : "/"}${curDir.split("/")[i]}`;
                  }

                  return (
                    <div
                      onClick={() => {
                        setCurDir(targetPath);
                        navigate(`/vience-canvas/storage/${targetPath}`);
                      }}
                      className="dir-name"
                      key={index}
                    >
                      <span>{dir}</span>
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.5791 18.7734L15.5791 12.7734L9.5791 6.77344"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  );
                } else
                  return (
                    <div className="dir-name" key={index}>
                      <span>{dir}</span>
                      <img
                        id="root-selector"
                        src={
                          process.env.PUBLIC_URL + theme === "dark"
                            ? "/images/data-root-down.svg"
                            : "/images/data-root-down-white.svg"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectOpen(!selectOpen);
                        }}
                        alt=""
                      />
                      {selectOpen && (
                        <ul id="root-select">
                          <li className="root-option" onClick={createDirectory}>
                            Create New Folder
                          </li>
                          <li
                            className="root-option"
                            onClick={renameCurDirectory}
                          >
                            Rename Current Folder
                          </li>
                          <li className="root-option">Delete Current Folder</li>
                        </ul>
                      )}
                    </div>
                  );
              })}
          </div>
          <div className="process-root-header-right">
            {curDir !== "" &&
              dirStatus.map((dirStatus, index) => (
                <div key={index}>
                  <div className="process-bar-title">{dirStatus[0]}</div>
                  <div className="process-bar progress-blue">
                    <div className="process-bar-value">
                      {dirStatus[1]}/{dirStatus[2]}
                    </div>
                    <span
                      style={{
                        background: `${dirStatus[3]}`,
                        width: `${100 * (dirStatus[1] / dirStatus[2])}%`,
                      }}
                    ></span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      <CanvasSearch setKeyword={setKeyword} />
    </>
  );
}
